import React, { useState, useEffect, useRef } from 'react';
import TopNavBar from '../HomePage/TopNavBar/TopNavBar';
import CommunitySidebar from '../HomePage/CommunitySidebar/CommunitySidebar';
import { FaStar, FaShare, FaCaretDown } from 'react-icons/fa';
import ProfilePicture from '../ProfilePicture/ProfilePicture';
import CreateStudyGroupForm from '../HomePage/CreateStudyGroupForm/CreateStudyGroupForm';
import taxios from '../../util/token_refresh_hook';
import { getUInfo } from '../../util/whoami';
import './OrganizationPage.css';
import { useNavigate } from 'react-router-dom';

// === NEW: import your pagination menu ===
import PaginationMenu from '../HomePage/Pagination/PMenuGeneric';  // <-- Adjust path if needed

const placeholderImage = 'https://via.placeholder.com/50';

const OrganizationPage = ({ toggleSidebar, sidebarOpen, onLogout, studyGroups, createStudyGroup }) => {
  // ====== FILTER & SORT STATES ======
  const [filterLocation, setFilterLocation] = useState('');
  const [filterIndustry, setFilterIndustry] = useState('');
  const [filterCompanySize, setFilterCompanySize] = useState('');
  const [sortFilter, setSortFilter] = useState('newest');

  // ====== ORGANIZATIONS PAGINATION ======
  const [page, setPage] = useState(1);          // Current page number
  const [maxPages, setMaxPages] = useState(1);  // Total # of pages from server
  const [organizations, setOrganizations] = useState([]);

  // ====== COURSES (still infinite scroll) ======
  const [courses, setCourses] = useState([]);

  // ====== UI CONTROLS ======
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [isLocationDropdownOpen, setIsLocationDropdownOpen] = useState(false);
  const [isIndustryDropdownOpen, setIsIndustryDropdownOpen] = useState(false);
  const [isCompanySizeDropdownOpen, setIsCompanySizeDropdownOpen] = useState(false);
  const [isSortDropdownOpen, setIsSortDropdownOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  // References for courses infinite scroll
  const courseLoadMoreRef = useRef(null);
  const [orgOffset, setOrgOffset] = useState(0);  

  const currentUser = getUInfo();
  const navigate = useNavigate();

  // ====== ON MOUNT: FETCH FIRST PAGE OF ORGS & COURSES ======
  useEffect(() => {
    fetchOrganizations(); // fetch page=1
    fetchCourses();       // courses still infinite scroll
    // eslint-disable-next-line
  }, []);

  // ====== WHENEVER PAGE OR FILTERS CHANGE, REFETCH ORGANIZATIONS ======
  useEffect(() => {
    fetchOrganizations();
    // eslint-disable-next-line
  }, [page, filterLocation, filterIndustry, filterCompanySize, sortFilter]);

  // ====== INFINITE SCROLL FOR COURSES ONLY (UNCHANGED) ======
  useEffect(() => {
    const courseObserver = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        loadMoreCourses();
      }
    });

    if (courseLoadMoreRef.current) {
      courseObserver.observe(courseLoadMoreRef.current);
    }
    return () => {
      if (courseLoadMoreRef.current) {
        courseObserver.unobserve(courseLoadMoreRef.current);
      }
    };
  }, [courseLoadMoreRef]);

  // --------------------------
  // BACKEND FETCHES
  // --------------------------

  /**
   * Fetch a single page of organizations from the backend,
   * sending filters + pagination in query params.
   */
  const fetchOrganizations = async () => {
    try {
      // Example: we use route GET /api/users/organizations/paged
      // or something similar that you created for aggregator-based approach
      const response = await taxios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/users/organizations/by`,
        {
          params: {
            page,
            usersPerPage: 5,
            location: filterLocation,
            industry: filterIndustry,
            companySize: filterCompanySize,
            sort: sortFilter,
          }
        }
      );
      // shape: { success, message, payload: { users, thisPage, pagination } }
      const { users = [], thisPage = {}, pagination = {} } =
        response.data.payload || {};

      // We only store the new page's organizations
      setOrganizations(users);
      setMaxPages(pagination.pages || 1);

    } catch (error) {
      console.error('Error fetching organizations:', error);
    }
  };

  /**
   * Fetch courses (still infinite scroll).
   */
  const fetchCourses = async () => {
    try {
      const response = await taxios.get(`${process.env.REACT_APP_BACKEND_URL}/api/courses`);
      // Sort by newest first
      const sortedCourses = response.data.payload.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      setCourses((prevCourses) => {
        const uniqueCourses = [...prevCourses, ...sortedCourses].filter(
          (course, index, self) => index === self.findIndex((c) => c._id === course._id)
        );
        return uniqueCourses;
      });
    } catch (error) {
      console.error('Error fetching courses:', error);
      setCourses([]);
    }
  };

// Load more courses (infinite scroll)
  const loadMoreCourses = () => {
    fetchCourses();
  };

  // --------------------------
  // EVENT HANDLERS
  // --------------------------

  const handleFollow = async (organizationId, isFollowing) => {
    if (isProcessing) return;
    setIsProcessing(true);

    try {
      const endpoint = isFollowing
        ? `${process.env.REACT_APP_BACKEND_URL}/api/users/unfollow/${organizationId}`
        : `${process.env.REACT_APP_BACKEND_URL}/api/users/follow/${organizationId}`;

      await taxios.post(endpoint);

      // Update local state to reflect follow/unfollow
      setOrganizations(prevOrganizations =>
        prevOrganizations.map(org =>
          org._id === organizationId
            ? {
                ...org,
                isFollowing: !isFollowing,
                followers: isFollowing
                  ? org.followers.filter(id => id !== currentUser.id)
                  : [...org.followers, currentUser.id]
              }
            : org
        )
      );
    } catch (error) {
      console.error(`Error ${isFollowing ? 'unfollowing' : 'following'} organization:`, error);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleCourseClick = (courseId) => {
    navigate(`/course/${courseId}`);
  };

  const handleShare = (courseTitle) => {
    const url = `https://example.com/courses/${courseTitle.replace(/\s+/g, '-').toLowerCase()}`;
    navigator.clipboard.writeText(url).then(() => {
      alert(`Link to "${courseTitle}" has been copied to clipboard: ${url}`);
    });
  };

  // No local next/prev; we rely on <PMenuGeneric> to handle page changes.
  // <PMenuGeneric> calls setPage() for us.

  return (
    <div className="organization-page">
      <TopNavBar
        toggleSidebar={toggleSidebar}
        onLogout={onLogout}
        onCreateStudyGroup={() => setShowCreateForm(true)}
      />
      <CommunitySidebar isOpen={sidebarOpen} />

      <div className={`content ${sidebarOpen ? 'sidebar-open' : ''}`}>
        {/* Create Group Form */}
        {showCreateForm && (
          <CreateStudyGroupForm onCreate={createStudyGroup} onClose={() => setShowCreateForm(false)} />
        )}

        {/* FILTER DROPDOWNS */}
        <div className="filter-container">
          {/* Sort */}
          <div className="filter-dropdown">
            <div
              className="filter-label"
              onClick={() => setIsSortDropdownOpen(!isSortDropdownOpen)}
            >
              Sort By <FaCaretDown className={`dropdown-icon ${isSortDropdownOpen ? 'open' : ''}`} />
            </div>
            {isSortDropdownOpen && (
              <div className="filter-options">
                <div className="filter-option" onClick={() => setSortFilter('newest')}>Newest</div>
                <div className="filter-option" onClick={() => setSortFilter('oldest')}>Oldest</div>
                <div className="filter-option" onClick={() => setSortFilter('followers')}>Followers</div>
              </div>
            )}
          </div>

          {/* Location */}
          <div className="filter-dropdown">
            <div
              className="filter-label"
              onClick={() => setIsLocationDropdownOpen(!isLocationDropdownOpen)}
            >
              Location <FaCaretDown className={`dropdown-icon ${isLocationDropdownOpen ? 'open' : ''}`} />
            </div>
            {isLocationDropdownOpen && (
              <div className="filter-options">
                <div className="filter-option" onClick={() => setFilterLocation('New York')}>
                  New York
                </div>
                <div className="filter-option" onClick={() => setFilterLocation('San Francisco')}>
                  San Francisco
                </div>
                <div className="filter-option" onClick={() => setFilterLocation('')}>
                  All
                </div>
              </div>
            )}
          </div>

          {/* Industry */}
          <div className="filter-dropdown">
            <div
              className="filter-label"
              onClick={() => setIsIndustryDropdownOpen(!isIndustryDropdownOpen)}
            >
              Industry <FaCaretDown className={`dropdown-icon ${isIndustryDropdownOpen ? 'open' : ''}`} />
            </div>
            {isIndustryDropdownOpen && (
              <div className="filter-options">
                <div
                  className="filter-option"
                  onClick={() => setFilterIndustry('Business Consulting and Services')}
                >
                  Business Consulting and Services
                </div>
                <div
                  className="filter-option"
                  onClick={() => setFilterIndustry('Professional Services')}
                >
                  Professional Services
                </div>
                <div
                  className="filter-option"
                  onClick={() => setFilterIndustry('Financial Services')}
                >
                  Financial Services
                </div>
                <div
                  className="filter-option"
                  onClick={() => setFilterIndustry('Technology')}
                >
                  Technology
                </div>
                <div
                  className="filter-option"
                  onClick={() => setFilterIndustry('Manufacturing')}
                >
                  Manufacturing
                </div>
                <div className="filter-option" onClick={() => setFilterIndustry('')}>
                  All
                </div>
              </div>
            )}
          </div>

          {/* Company Size */}
          <div className="filter-dropdown">
            <div
              className="filter-label"
              onClick={() => setIsCompanySizeDropdownOpen(!isCompanySizeDropdownOpen)}
            >
              Company Size <FaCaretDown className={`dropdown-icon ${isCompanySizeDropdownOpen ? 'open' : ''}`} />
            </div>
            {isCompanySizeDropdownOpen && (
              <div className="filter-options">
                <div className="filter-option" onClick={() => setFilterCompanySize('1000')}>
                  Less than 1000
                </div>
                <div className="filter-option" onClick={() => setFilterCompanySize('10000')}>
                  Less than 10000
                </div>
                <div className="filter-option" onClick={() => setFilterCompanySize('20000')}>
                  Less than 20000
                </div>
                <div className="filter-option" onClick={() => setFilterCompanySize('20001')}>
                  More than 20000
                </div>
                <div className="filter-option" onClick={() => setFilterCompanySize('')}>
                  All
                </div>
              </div>
            )}
          </div>
        </div>

        <PaginationMenu
          currentPage={page}
          maxPages={maxPages}
          setCurrentPage={setPage}
        />

        {/* MAIN SECTION */}
        <div className="main-section">

        

          {/* ORGANIZATIONS */}
          <div className="organizations-container">


            {organizations.map((org) => {
              const isFollowing =
                Array.isArray(org.followers) && org.followers.includes(currentUser.id);

              return (
                <div
                  key={org._id}
                  className="organization-card"
                  onClick={() => navigate(`/profile/${org._id}`)}
                >
                  <div className="profile-picture">
                    <img
                      src={org.avatar || placeholderImage}
                      alt={`${org.name} profile`}
                    />
                  </div>
                  <div className="organization-info">
                    <h3>
                      {org.name} <span>•</span>{' '}
                      {org.industry || 'Industry not specified'}
                    </h3>
                    <p>{org.description}</p>
                    <span>{org.followers.length} Followers</span>
                    <span>
                      {org.companySize
                        ? org.companySize.toLocaleString()
                        : 'Unknown'}{' '}
                      Employees
                    </span>
                  </div>
                  <button
                    className={`follow-button ${isFollowing ? 'following' : ''}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleFollow(org._id, isFollowing);
                    }}
                    disabled={isProcessing}
                  >
                    {isFollowing ? 'Following' : 'Follow'}
                  </button>
                </div>
              );
            })}


          </div>

          {/* COURSES SECTION (unchanged, still infinite scroll) */}
          <div className="courses-container">
            <h2 className="courses-title">Courses</h2>
            <div className="courses-list">
              {courses.map((course, index) => (
                <div
                  key={course._id || index}
                  className="course-item"
                  onClick={() => handleCourseClick(course._id)}
                >
                  <div className="course-thumbnail">
                    <img
                      src={
                        course?.courseThumbnailURL
                          ? course.courseThumbnailURL.startsWith('http')
                            ? course.courseThumbnailURL
                            : `https://archi-video-bucket.s3.us-west-1.amazonaws.com/thumbnails/${course.courseThumbnailURL}`
                          : 'https://via.placeholder.com/150'
                      }
                      alt="Course Thumbnail"
                    />
                  </div>
                  <div className="course-info">
                    <ProfilePicture
                      src={
                        course.creatorID.profilePicture || placeholderImage
                      }
                      alt={course.creatorID.username || 'Anonymous'}
                    />
                    <div className="course-details">
                      <h2>{course.courseTitle}</h2>
                      <span className="creator-name">
                        {course.creatorID.username}
                      </span>
                      <div className="rating">
                        {[...Array(5)].map((_, i) => (
                          <FaStar
                            key={i}
                            color={
                              i < Math.round(course.rating) ? '#ffc107' : '#e4e9f0'
                            }
                          />
                        ))}
                        <span>{course.rating?.toFixed(1)}</span>
                      </div>
                    </div>
                    <FaShare
                      className="share-icon"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleShare(course.courseTitle);
                      }}
                    />
                  </div>
                </div>
              ))}
              <div ref={courseLoadMoreRef}></div>
            </div>            

          </div>
        </div>

        <PaginationMenu
          currentPage={page}
          maxPages={maxPages}
          setCurrentPage={setPage}
        />
      </div>
    </div>
  );
};

export default OrganizationPage;
