import React, { useState } from "react";
import axios from "axios";
import "./LoginPage.css";
import Logo from "../Style/Logo";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Cookies from "js-cookie";

const LoginPage = ({ onLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [persistSession, setPersistSession] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [keepSignedIn, setKeepSignedIn] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/users/login`,
        { username, password, persistSession },
        { withCredentials: true }
      );

      if (response.status === 200) {
        /*
        if (keepSignedIn) {
          Cookies.set('token', response.data.token, { expires: 7 });
        } else {
          Cookies.set('token', response.data.token);
        }
		  */

        onLogin(response.data);
        navigate("/app");
      } else {
        console.error("Login failed:", response.data);
      }
    } catch (error) {
      console.error("There was an error logging in!", error);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="login-page-container">
      <div className="login-form-wrapper">
        <div className="login-logo-section">
          <Logo className="login-logo-image" />
          <p className="login-welcome-back"> Welcome back!</p>
        </div>
        <form className="login-form" onSubmit={handleLogin}>
          <input
            type="text"
            placeholder="User Name"
            className="login-form-input"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <div className="login-password-input-container">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              className="login-form-input"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button
              type="button"
              className="login-toggle-password-button"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          <div className="login-options-container">
            <label className="login-keep-signed-in">
              <input
                type="checkbox"
                checked={persistSession}
                onChange={(e) => setPersistSession(e.target.checked)}
              />
              Keep me signed in
            </label>
            <a href="#" className="login-forgot-password-link">
              Forgot Password
            </a>
          </div>
          <button type="submit" className="login-submit-button">
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
