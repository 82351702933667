import React, { useState, useEffect } from "react";
import { FaChevronDown, FaStar, FaStarHalfAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import TopNavBar from "../HomePage/TopNavBar/TopNavBar";
import CommunitySidebar from "../HomePage/CommunitySidebar/CommunitySidebar";
import taxios from "../../util/token_refresh_hook";
import ProfilePicture from "../ProfilePicture/ProfilePicture";
import CreateStudyGroupForm from "../HomePage/CreateStudyGroupForm/CreateStudyGroupForm";
import { getUInfo } from "../../util/whoami";
import PaginationMenu from "../HomePage/Pagination/PMenuGeneric"; 
import "./EducatorPage.css";

const placeholderImage = "https://via.placeholder.com/50";

const EducatorPage = ({
  toggleSidebar,
  sidebarOpen,
  onLogout,
  studyGroups,
  createStudyGroup,
}) => {
  // Current logged-in user info
  const currentUser = getUInfo();
  const navigate = useNavigate();

  // === FILTER STATES ===
  const [filterLocation, setFilterLocation] = useState("");
  const [filterSubject, setFilterSubject] = useState("");
  const [filterExperience, setFilterExperience] = useState("");
  const [filterRating, setFilterRating] = useState("");

  // === DROPDOWN VISIBILITY ===
  const [locationOpen, setLocationOpen] = useState(false);
  const [subjectOpen, setSubjectOpen] = useState(false);
  const [experienceOpen, setExperienceOpen] = useState(false);
  const [ratingOpen, setRatingOpen] = useState(false);

  // === PAGINATION STATES ===
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  // === DATA ARRAYS ===
  const [educators, setEducators] = useState([]);
  const [courses, setCourses] = useState([]);

  // === FILTER OPTION ARRAYS ===
  const [locations, setLocations] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [experiences, setExperiences] = useState([]);
  const [ratings, setRatings] = useState([]);

  // === STUDY GROUP MODAL ===
  const [showCreateForm, setShowCreateForm] = useState(false);

  // =============================
  // 1) FETCH EDUCATORS (Now Replacing Data Each Page)
  // =============================
  const fetchEducators = async (pageNumber) => {
    try {
      // Build query params
      const params = {
        page: pageNumber,
        limit: 5, // or 10, or 20...
      };
      if (filterLocation)   params.location   = filterLocation;
      if (filterSubject)    params.subject    = filterSubject;
      if (filterExperience) params.experience = filterExperience;
      if (filterRating)     params.rating     = filterRating;

      const response = await taxios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/users/educators/by`,
        { params }
      );

      const { educators: newEducators = [], totalPages: tPages = 1 } =
        response.data.payload ?? {};

      // Collect unique filter data
      const uniqueLocations = [
        ...new Set([...locations, ...newEducators.map((edu) => edu.location)]),
      ].filter(Boolean);

      const uniqueSubjects = [
        ...new Set([...subjects, ...newEducators.map((edu) => edu.subject)]),
      ].filter(Boolean);

      const uniqueExperiences = [
        ...new Set([
          ...experiences,
          ...newEducators.map((edu) => {
            if (edu.experience < 5) return "Less than 5 years";
            if (edu.experience < 10) return "Less than 10 years";
            if (edu.experience < 15) return "Less than 15 years";
          }),
        ]),
      ];

      const uniqueRatings = [
        ...new Set([
          ...ratings,
          ...newEducators.map((edu) => Math.round(edu.rating || 0)),
        ]),
      ].sort();

      // Update filter arrays
      setLocations(uniqueLocations);
      setSubjects(uniqueSubjects);
      setExperiences(uniqueExperiences);
      setRatings(uniqueRatings);

      // Mark which educators are followed by current user
      const updatedEducators = newEducators.map((edu) => ({
        ...edu,
        isFollowing: edu.followers?.includes(currentUser?.id),
        userRating: edu.userRating || 0,
        rating: edu.rating || 0,
      }));

      // IMPORTANT: For pagination, REPLACE rather than append:
      setEducators(updatedEducators);
      setTotalPages(tPages);
    } catch (error) {
      console.error("Error fetching educators:", error);
    }
  };

  // =============================
  // 2) FETCH COURSES (Optional)
  // =============================
  const fetchCourses = async () => {
    try {
      const response = await taxios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/courses`
      );
      const sortedCourses = response.data.payload.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      setCourses(sortedCourses);
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  };
  // =============================
  // 3) HOOKS
  // =============================
  // On mount: fetch courses + educators (page 1)
  useEffect(() => {
    fetchCourses();
    fetchEducators(1);
    // eslint-disable-next-line
  }, []);

  // Whenever `page` changes, fetch that page
  useEffect(() => {
    fetchEducators(page);
    // eslint-disable-next-line
  }, [page]);

  // If filters change, reset to page 1
  useEffect(() => {
    setPage(1);
    fetchEducators(1);
    // eslint-disable-next-line
  }, [filterLocation, filterSubject, filterExperience, filterRating]);

  // =============================
  // 4) FOLLOW/UNFOLLOW + RATING
  // =============================
  const handleFollow = async (educatorId, isFollowing) => {
    try {
      const endpoint = isFollowing
        ? `${process.env.REACT_APP_BACKEND_URL}/api/users/unfollow/${educatorId}`
        : `${process.env.REACT_APP_BACKEND_URL}/api/users/follow/${educatorId}`;
      await taxios.post(endpoint);

      setEducators((prevEducators) =>
        prevEducators.map((edu) =>
          edu._id === educatorId
            ? {
                ...edu,
                isFollowing: !isFollowing,
                followers: isFollowing
                  ? edu.followers.filter((id) => id !== currentUser?.id)
                  : [...edu.followers, currentUser?.id],
              }
            : edu
        )
      );
    } catch (error) {
      console.error(
        `Error ${isFollowing ? "unfollowing" : "following"} educator:`,
        error
      );
    }
  };

  const handleRatingClick = async (e, educatorId, starValue) => {
    const { left, width } = e.target.getBoundingClientRect();
    const clickX = e.clientX - left;
    const isHalf = clickX < width / 2;
    const newRating = isHalf ? starValue - 0.5 : starValue;

    try {
      const response = await taxios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/educators/${educatorId}/rate`,
        { rating: newRating }
      );
      setEducators((prevEducators) =>
        prevEducators.map((edu) =>
          edu._id === educatorId
            ? {
                ...edu,
                userRating: newRating,
                rating: response.data.payload?.rating ?? newRating,
              }
            : edu
        )
      );
    } catch (error) {
      console.error("Error updating rating:", error);
    }
  };

  const renderStars = (userRating, rating, educatorId) => {
    const combinedRating = userRating || rating;
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      if (combinedRating >= i) {
        stars.push(
          <FaStar
            key={i}
            size={24}
            onClick={(e) => handleRatingClick(e, educatorId, i)}
            color="#ffc107"
            className="rating-star"
          />
        );
      } else if (combinedRating >= i - 0.5) {
        stars.push(
          <FaStarHalfAlt
            key={i}
            size={24}
            onClick={(e) => handleRatingClick(e, educatorId, i)}
            color="#ffc107"
            className="rating-star"
          />
        );
      } else {
        stars.push(
          <FaStar
            key={i}
            size={24}
            onClick={(e) => handleRatingClick(e, educatorId, i)}
            color="#e4e9f0"
            className="rating-star"
          />
        );
      }
    }
    return stars;
  };

  // =============================
  // 5) COURSE HELPER
  // =============================
  const handleCourseClick = (courseId) => {
    navigate(`/course/${courseId}`);
  };

  // =============================
  // 6) RENDER
  // =============================
  return (
    <div className="educator-page">
      <TopNavBar
        toggleSidebar={toggleSidebar}
        onLogout={onLogout}
        onCreateStudyGroup={() => setShowCreateForm(true)}
      />
      <CommunitySidebar isOpen={sidebarOpen} />

      <div className={`content ${sidebarOpen ? "sidebar-open" : ""}`}>
        {showCreateForm && (
          <CreateStudyGroupForm
            onCreate={createStudyGroup}
            onClose={() => setShowCreateForm(false)}
          />
        )}

        {/* === FILTERS === */}
        <div className="filter-container">
          {/* Location */}
          <div className="filter-dropdown">
            <div
              className="filter-label"
              onClick={() => setLocationOpen(!locationOpen)}
            >
              Location <FaChevronDown className={`dropdown-icon ${locationOpen ? "open" : ""}`} />
            </div>
            {locationOpen && (
              <div className="filter-options">
                {locations.map((loc) => (
                  <div
                    key={loc}
                    className="filter-option"
                    onClick={() => {
                      setFilterLocation(loc);
                      setLocationOpen(false);
                    }}
                  >
                    {loc}
                  </div>
                ))}
                <div
                  className="filter-option"
                  onClick={() => {
                    setFilterLocation("");
                    setLocationOpen(false);
                  }}
                >
                  All Locations
                </div>
              </div>
            )}
          </div>

          {/* Subject */}
          <div className="filter-dropdown">
            <div
              className="filter-label"
              onClick={() => setSubjectOpen(!subjectOpen)}
            >
              Subject <FaChevronDown className={`dropdown-icon ${subjectOpen ? "open" : ""}`} />
            </div>
            {subjectOpen && (
              <div className="filter-options">
                {subjects.map((sub) => (
                  <div
                    key={sub}
                    className="filter-option"
                    onClick={() => {
                      setFilterSubject(sub);
                      setSubjectOpen(false);
                    }}
                  >
                    {sub}
                  </div>
                ))}
                <div
                  className="filter-option"
                  onClick={() => {
                    setFilterSubject("");
                    setSubjectOpen(false);
                  }}
                >
                  All Subjects
                </div>
              </div>
            )}
          </div>

          {/* Experience */}
          <div className="filter-dropdown">
            <div
              className="filter-label"
              onClick={() => setExperienceOpen(!experienceOpen)}
            >
              Experience <FaChevronDown className={`dropdown-icon ${experienceOpen ? "open" : ""}`} />
            </div>
            {experienceOpen && (
              <div className="filter-options">
                {experiences.map((exp) => (
                  <div
                    key={exp}
                    className="filter-option"
                    onClick={() => {
                      setFilterExperience(exp);
                      setExperienceOpen(false);
                    }}
                  >
                    {exp}
                  </div>
                ))}
                <div
                  className="filter-option"
                  onClick={() => {
                    setFilterExperience("");
                    setExperienceOpen(false);
                  }}
                >
                  All Experience Levels
                </div>
              </div>
            )}
          </div>

          {/* Rating */}
          <div className="filter-dropdown">
            <div
              className="filter-label"
              onClick={() => setRatingOpen(!ratingOpen)}
            >
              Rating <FaChevronDown className={`dropdown-icon ${ratingOpen ? "open" : ""}`} />
            </div>
            {ratingOpen && (
              <div className="filter-options">
                {ratings.map((rt) => (
                  <div
                    key={rt}
                    className="filter-option"
                    onClick={() => {
                      setFilterRating(rt.toString());
                      setRatingOpen(false);
                    }}
                  >
                    {rt} Stars
                  </div>
                ))}
                <div
                  className="filter-option"
                  onClick={() => {
                    setFilterRating("");
                    setRatingOpen(false);
                  }}
                >
                  All Ratings
                </div>
              </div>
            )}
          </div>
        </div>

        {/* === PAGINATION MENU (TOP) === */}
        <PaginationMenu
          currentPage={page}
          maxPages={totalPages}
          setCurrentPage={setPage}
        />

        <div className="main-section">
          {/* EDUCATORS LIST */}
          <div className="educators-container">
            {educators.map((edu) => (
              <div
                key={edu._id}
                className="educator-card"
                onClick={() => navigate(`/profile/${edu._id}`)}
              >
                <div className="profile-picture">
                  <img
                    src={edu.avatar || placeholderImage}
                    alt={`${edu.username} profile`}
                  />
                </div>
                <div className="educator-info">
                  <h3>
                    <span className="educator-name">{edu.username}</span>
                    <span className="dot-divider">•</span>
                    <span className="educator-subject">{edu.subject}</span>
                  </h3>
                  <p>{edu.description}</p>
                  <span>{edu.followers?.length || 0} Followers</span>
                  <span>{edu.experience} Years of Experience</span>
                  <div className="rating-section">
                    {renderStars(edu.userRating, edu.rating, edu._id)}
                    <span className="numeric-rating">
                      {(edu.userRating || edu.rating).toFixed(1)} / 5
                    </span>
                  </div>
                </div>
                <button
                  className={`follow-button ${edu.isFollowing ? "following" : ""}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleFollow(edu._id, edu.isFollowing);
                  }}
                >
                  {edu.isFollowing ? "Following" : "Follow"}
                </button>
              </div>
            ))}
            {educators.length === 0 && (
              <div style={{ marginTop: "20px" }}>No educators found.</div>
            )}
          </div>

          {/* COURSES LIST */}
          <div className="courses-container">
            <h2 className="courses-title">Courses</h2>
            <div className="courses-list">
              {courses.map((course) => (
                <div
                  key={course._id}
                  className="course-item"
                  onClick={() => handleCourseClick(course._id)}
                >
                  <div className="course-thumbnail">
                    <img
                      src={
                        course?.courseThumbnailURL
                          ? course.courseThumbnailURL.startsWith("http")
                            ? course.courseThumbnailURL
                            : `https://some-s3-bucket/${course.courseThumbnailURL}`
                          : "https://via.placeholder.com/150"
                      }
                      alt="Course Thumbnail"
                    />
                  </div>
                  <div className="course-info">
                    <ProfilePicture
                      src={course.creatorID?.avatar || placeholderImage}
                      alt={course.creatorID?.username || "Anonymous"}
                    />
                    <div className="course-details">
                      <h2>{course.courseTitle}</h2>
                      <span className="creator-name">
                        {course.creatorID?.username}
                      </span>
                      <div className="rating">
                        {[...Array(5)].map((_, i) => (
                          <FaStar
                            key={i}
                            color={
                              i < Math.round(course.rating) ? "#ffc107" : "#e4e9f0"
                            }
                          />
                        ))}
                        <span>{course.rating?.toFixed(1)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {courses.length === 0 && (
                <div style={{ marginTop: "20px" }}>No courses found.</div>
              )}
            </div>
          </div>
          
        </div>

        {/* === PAGINATION MENU (BOTTOM) === */}
        <PaginationMenu
          currentPage={page}
          maxPages={totalPages}
          setCurrentPage={setPage}
        />
      </div>
    </div>
  );
};

export default EducatorPage;
